import React from 'react';
import { Container, Heading, VStack } from '@chakra-ui/react';

const Lives = () => {
  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={8} align="center">
        <Heading as="h1" size="xl">
          Aktualne Transmisje na Żywo
        </Heading>

        {/* Twitch Stream */}
        <iframe
                src="https://player.twitch.tv/?channel=wizjatvpolska&parent=wizjatvpolska.pl"
                height="378"
                width="620"
                allowFullScreen={true}
                frameBorder="0"
                scrolling="no"
                title="Twitch Stream"
                ></iframe>


        {/* Kick Stream */}
        <iframe
          src="https://player.kick.com/wizjatvpolska?allowfullscreen=false"
          height="378"
          width="620"
          frameBorder="0"
          scrolling="no"
          title="Kick Stream"
        ></iframe>

        {/* Popler Stream */}
        <div
          id="player"
          itemProp="video"
          itemScope
          style={{
            float: 'left',
            display: 'block',
            zIndex: 99,
            backgroundColor: '#000',
            color: '#FFF',
            textAlign: 'center',
          }}
        >
          <iframe
            id="iframeplayer"
            width="620"
            height="350"
            src="//www.popler.tv/embed/player.php?user=wizjatvpolska&amp;popler=1&amp;kody_code="
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
            title="Popler Stream"
          ></iframe>
        </div>
      </VStack>
    </Container>
  );
};

export default Lives;
