import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Home from './components/Home';
import Contact from './components/Contact';
import Videos from './components/Videos';
import NavBar from './components/NavBar';
import './App.css'; // Import pliku CSS do stylizacji przejść
import Lives from './components/Lives';

function App() {
  const location = useLocation();

  return (
    <>
      <NavBar />
      <SwitchTransition>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300}
        >
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/lives" element={<Lives />} />

          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}

export default App;
