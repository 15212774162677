import React, { useState, useEffect } from 'react';
import { Box, Container, Heading, Text, VStack, useToast, Image, Flex, Button, Badge } from '@chakra-ui/react';
import Carousel from './Carousel';

type WeatherData = {
  main: {
    temp: number;
  };
  weather: {
    description: string;
  }[];
  wind: {
    speed: number;
  };
};

const socialLinks = [
  { platform: 'YouTube', link: 'https://www.youtube.com/@wizjatvpolska01', imgSrc: 'https://i.imgur.com/57E4zX1.png' },
  { platform: 'Twitch', link: 'https://www.twitch.tv/wizjatvpolska', imgSrc: 'https://static.vecteezy.com/system/resources/previews/023/986/749/original/twitch-logo-twitch-logo-transparent-twitch-icon-transparent-free-free-png.png' },
  { platform: 'PopplerTV', link: 'https://www.popler.tv/wizjatvpolska', imgSrc: 'https://www.popler.tv/oferta_new/images/logo.png' },
  { platform: 'Kick', link: 'https://kick.com/wizjatvpolska', imgSrc: 'https://i.imgur.com/zxAdIX3.png' },
  { platform: 'Facebook', link: 'https://www.facebook.com/WizjaTvPolska', imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg' },
  { platform: 'TikTok', link: 'https://www.tiktok.com/@wizjatvpolska', imgSrc: 'https://img.freepik.com/premium-wektory/logo-tik-tok_578229-290.jpg' },
  { platform: 'X', link: 'https://x.com/wizjatvpolskaa', imgSrc: 'https://img.freepik.com/premium-wektory/nowy-wektor-logo-twitter-x-wektor-znaku-twitter-x_952796-6.jpg?semt=ais_hybrid' },

];

const Home = () => {
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        // Zastąp 'YOUR_API_KEY' prawdziwym kluczem API
        const apiKey = '0774b61fe48b24935d2b7ad30d62b891';
        const city = 'Miedzichowo';
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric&lang=pl`);

        if (!response.ok) {
          throw new Error('Nie udało się pobrać danych pogodowych');
        }

        const data: WeatherData = await response.json();
        setWeather(data);
      } catch (err) {
        const errorMessage = (err as Error).message;
        setError(errorMessage);
        toast({
          title: 'Błąd',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchWeather();
  }, [toast]);

  const images = [
    { src: 'https://i.imgur.com/02M8bbY.png', alt: 'Film 1' },
    { src: 'https://i.imgur.com/h6GmKt7.png', alt: 'Film 2' },
    { src: 'https://i.imgur.com/DrGooPu.png', alt: 'Film 3' },
  ];

  return (
    <>
      <Carousel images={images} />
      {weather && (
        <Box position="fixed" bottom="20px" left="20px" bg="#222735" p="4" borderRadius="md" color="white" boxShadow="2xl">
          <Heading as="h6" size="md">Aktualna pogoda w Miedzichowie</Heading>
          <Text>Temperatura: <Badge>{weather.main.temp}°C</Badge></Text>
          <Text>Warunki: <Badge>{weather.weather[0].description}</Badge></Text>
          <Text>Wiatr: <Badge>{weather.wind.speed} m/s</Badge></Text>
        </Box>
      )}
      {error && (
        <Box position="fixed" bottom="20px" left="20px" bg="red.600" p="4" borderRadius="md" color="white" boxShadow="2xl">
          <Heading as="h6" size="md">Błąd</Heading>
          <Text>{error}</Text>
        </Box>
      )}
      <Container maxW="container.xl" py={10}>
        <Heading
          as="h3"
          size="xl"
          mb={10}
          textAlign="center"
          bgGradient="linear(to-r, teal.400, blue.500)"
          bgClip="text"
          fontWeight="extrabold"
        >
          Znajdź nas na
        </Heading>
        <Flex justify="center" wrap="nowrap" overflowX="unset" p={6}>
          {socialLinks.map((item, index) => (
            <Box
              key={index}
              as="a"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              borderRadius="lg"
              boxShadow="xl"
              _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
              transition="all 0.3s"
              mx={3}
              bg="#212735"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={6}
              minW="200px"
            >
              <Image src={item.imgSrc} alt={item.platform} maxH="80px" objectFit="contain" mb={4} />
              <Heading as="h5" size="md" textAlign="center" color="gray.700">
                {item.platform}
              </Heading>
            </Box>
          ))}
        </Flex>
      </Container>
    </>
  );
};

export default Home;
