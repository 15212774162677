import { Box, Flex, Link as ChakraLink, IconButton, Image, useColorMode } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      bg={colorMode === 'dark' ? 'gray.800' : 'white'}
      boxShadow="md"
      px={4}
      py={2}
      position="sticky"
      top={0}
      zIndex={10}
    >
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Image 
            src="https://i.imgur.com/IpAeFQE.png" 
            alt="WizjaTV Polska Logo" 
            h={90} 
          />
        </Flex>
        <Flex alignItems={'center'}>
          <ChakraLink
            as={Link}
            to="/"
            px={3}
            py={2}
            rounded="md"
            _hover={{
              textDecoration: 'none',
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            }}
          >
            Strona główna
          </ChakraLink>
          <ChakraLink
            as={Link}
            to="/videos"
            px={3}
            py={2}
            rounded="md"
            _hover={{
              textDecoration: 'none',
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            }}
          >            
          Ostatnie Filmy
          </ChakraLink>
          <ChakraLink
            as={Link}
            to="/lives"
            px={3}
            py={2}
            rounded="md"
            _hover={{
              textDecoration: 'none',
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            }}
          >
            Transmisje na żywo
          </ChakraLink>
          <ChakraLink
            as={Link}
            to="/contact"
            px={3}
            py={2}
            rounded="md"
            _hover={{
              textDecoration: 'none',
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            }}
          >
            Kontakt
          </ChakraLink>
          <IconButton
            onClick={toggleColorMode}
            icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
            ml={4}
            aria-label="Toggle color mode"
            variant="ghost"
            _hover={{
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.200',
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
