import React, { useState, useEffect } from 'react';
import {
  Container,
  Heading,
  HStack,
  Box,
  Image,
  Text,
  Button,
  Input,
  VStack,
} from '@chakra-ui/react';

interface Video {
  title: string;
  thumbnailUrl: string;
  videoUrl: string;
}

const Videos: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editVideoIds, setEditVideoIds] = useState<string[]>([]);

  useEffect(() => {
    const storedVideos = localStorage.getItem('videos');
    if (storedVideos) {
      setVideos(JSON.parse(storedVideos));
    } else {
      // Default video IDs if nothing is stored
      fetchVideos(['61WvaZtRKvQ', 'a9VihBev4Pg', 'v3VZSTfVpZE']);
    }
  }, []);

  const fetchVideos = async (videoIds: string[]) => {
    const fetchedVideos = await Promise.all(
      videoIds.map(async (videoId): Promise<Video> => {
        const response = await fetch(
          `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`
        );
        const data = await response.json();
        return {
          title: data.title,
          thumbnailUrl: data.thumbnail_url,
          videoUrl: `https://www.youtube.com/watch?v=${videoId}`,
        };
      })
    );
    setVideos(fetchedVideos);
    localStorage.setItem('videos', JSON.stringify(fetchedVideos));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveChanges = () => {
    fetchVideos(editVideoIds);
    setIsEditing(false);
  };

  return (
    <Container maxW="container.md" py={10}>
      <Heading as="h1" size="xl" mb={6}>
        Ostatnie Filmy
      </Heading>
      {isEditing ? (
        <VStack spacing={3}>
          {videos.map((video, index) => (
            <Input
              key={index}
              placeholder={`YouTube Video ID ${index + 1}`}
              value={editVideoIds[index] || ''}
              onChange={(e) => {
                const newIds = [...editVideoIds];
                newIds[index] = e.target.value;
                setEditVideoIds(newIds);
              }}
            />
          ))}
          <Button colorScheme="green" onClick={handleSaveChanges}>
            Zapisz zmiany
          </Button>
        </VStack>
      ) : (
        <HStack spacing={5} align="start">
          {videos.map((video, index) => (
            <Box
              key={index}
              as="a"
              href={video.videoUrl}
              target="_blank"
              rel="noopener noreferrer"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={4}
              w="350px"
              h="350px"
              minW="300px"
              minH="350px"
            >
              <Image
                src={video.thumbnailUrl}
                alt={video.title}
                borderRadius="md"
                mb={4}
                w="100%"
                h="200px"
                objectFit="cover"
              />
              <Text fontSize="lg" fontWeight="bold" textAlign="center">
                {video.title}
              </Text>
            </Box>
          ))}
        </HStack>
      )}
      {!isEditing && (
        <Button colorScheme="blue" mt={6} onClick={handleEditClick}>
          Edytuj
        </Button>
      )}
    </Container>
  );
};

export default Videos;
