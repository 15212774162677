import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Image } from '@chakra-ui/react';

interface CarouselProps {
  images: { src: string; alt: string }[];
}

const CustomCarousel: React.FC<CarouselProps> = ({ images }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Box
      width="100%" // Carousel takes the full width of the page
      mx="auto" // Center the carousel horizontally
      overflow="hidden"
      borderRadius="md"
      boxShadow="lg"
    >
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000} // Change slide every 5 seconds
        infinite={true}
        keyBoardControl={true}
        showDots={true} // Show dots for navigation
        arrows={true} // Show navigation arrows
        containerClass="carousel-container"
        itemClass="carousel-item"
      >
        {images.map((image, index) => (
          <Box key={index} textAlign="center">
            <Image
              src={image.src}
              alt={image.alt}
              objectFit="cover"
              width="100%"
              height="400px"
              mx="auto"
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default CustomCarousel;
