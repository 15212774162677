import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import theme from './theme';

const container = document.getElementById('root');

// Sprawdzenie, czy container nie jest null
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  );
} else {
  console.error("Root element not found. Make sure there is an element with id 'root' in your HTML.");
}
