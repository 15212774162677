import React from 'react';
import { Container, Heading, Text, VStack, HStack, Input, Textarea, Button, Icon, Link, Box } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={8} align="start">
        <Heading as="h1" size="2xl" mb={4}>
          Kontakt
        </Heading>
        <Text fontSize="lg">
          Jeśli masz jakiekolwiek pytania, zapraszamy do kontaktu poprzez nasze media społecznościowe lub email:
        </Text>
        <Text fontSize="lg">
          Email: <Link href="mailto:wizjatvpolska@gmail.com" color="teal.500">wizjatvpolska@gmail.com</Link>
        </Text>

        <VStack spacing={5} align="start" width="100%">
          <Heading as="h2" size="lg" mb={2}>
            Formularz kontaktowy
          </Heading>
          <Input placeholder="Twoje Imię" size="lg" />
          <Input placeholder="Twój Email" type="email" size="lg" />
          <Textarea placeholder="Twoja Wiadomość" size="lg" resize="vertical" />
          <Button colorScheme="teal" size="lg">
            Wyślij Wiadomość
          </Button>
        </VStack>

        <VStack spacing={5} align="start" width="100%">
          <Heading as="h2" size="lg" mb={2}>
            Znajdź nas na:
          </Heading>
          <HStack spacing={5}>
            <Link href="https://www.facebook.com" isExternal>
              <Icon as={FaFacebook} boxSize={8} color="teal.500" />
            </Link>
            <Link href="https://www.twitter.com" isExternal>
              <Icon as={FaTwitter} boxSize={8} color="teal.500" />
            </Link>
            <Link href="https://www.instagram.com" isExternal>
              <Icon as={FaInstagram} boxSize={8} color="teal.500" />
            </Link>
          </HStack>
        </VStack>

      </VStack>
    </Container>
  );
};

export default Contact;
